<template>
  <v-container>
    <v-form ref="member-form" @submit.prevent="formSubmit">
      <v-row class="justify-space-between">
        <div class="d-flex">
          <v-btn @click.prevent="$router.go(-1)" plain :disabled="isFetching">
            <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            <div>上一頁</div>
          </v-btn>
        </div>
        <div v-if="$validate.DataValid(id) && userType === 'normal'">
          <v-btn color="primary" small class="mx-4" :disabled="isFetching" depressed>查看上堂記錄</v-btn>
          <v-btn color="primary" small class="mx-4" :disabled="isFetching" depressed>查看購買記錄</v-btn>
          <FormDeleteBtn @removeAction="removeNormalUser"></FormDeleteBtn>
        </div>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card rounded="lg" class="pa-5">
            <v-card-subtitle>
              <div class="page-title">{{ $route.meta.title }}</div>
            </v-card-subtitle>
            <v-card-text class="my-5">
              <v-row>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="select"
                    v-model="status"
                    :options="statusList"
                    label="狀態"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>
                <v-col cols="12" md="6" class="d-flex align-center">
                  <v-btn
                    v-if="userType === 'normal'"
                    color="primary"
                    small
                    class="mx-4"
                    depressed
                    :disabled="isFetching"
                    >重設密碼</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="string"
                    v-model="name"
                    label="用戶名稱"
                    :required="true"
                    :disabled="isFetching"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="select"
                    v-model="userType"
                    :options="userTypeList"
                    label="用戶類型"
                    :required="true"
                    :readonly="true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="email"
                    v-model="email"
                    label="電郵地址"
                    :required="true"
                    :disabled="isFetching"
                    :readonly="true"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <form-control
                    inputType="phone"
                    v-model="phone"
                    label="聯絡電話"
                    :disabled="isFetching"
                    :allowEmpty="true"
                    :validatePhone="true"
                    :validateNumber="true"
                    :inputNumberOnly="true"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" v-if="userType === 'normal'">
                  <form-control
                    inputType="string"
                    v-model="refCode"
                    label="推薦碼"
                    :required="true"
                    :disabled="isFetching"
                    :readonly="true"
                  />
                </v-col>

                <template v-else>
                  <v-col cols="12" md="6">
                    <form-control
                      inputType="string"
                      v-model="brNumber"
                      label="BR編號"
                      :required="true"
                      :disabled="isFetching"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="align-self-center">
                    <FormButtonUpload
                      :uploadFile.sync="brFile"
                      :acceptFormat="'imageWithPDF'"
                      :uploadText="'上載BR'"
                    ></FormButtonUpload>
                    <div class="error-msg">{{ uploadError }}</div>
                  </v-col>
                </template>

                <v-col cols="12" md="6">
                  <v-select
                    label="會員等級"
                    color="indigo darken-4"
                    persistent-placeholder
                    :items="typeList"
                    v-model="userType"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details="auto"
                    :disabled="isFetching"
                    no-data-text="沒有資料"
                    :rules="[
                      v => {
                        return this.required ? this.$validate.required(v) || '此位置不能留空' : true
                      },
                    ]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" class="px-6" small depressed :disabled="isFetching" type="submit">儲存</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl.vue'
import { mdiChevronLeft } from '@mdi/js'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'
import FormDeleteBtn from '@/components/form/FormDeleteBtn.vue'

export default {
  name: 'MemberDetail',
  components: {
    FormControl,
    FormButtonUpload,
    FormDeleteBtn,
  },
  setup() {
    return {
      icons: {
        mdiChevronLeft,
      },
    }
  },
  data() {
    return {
      submitSuccess: false,

      name: '',
      status: '',
      userType: '',
      email: '',
      phone: '',
      refCode: '',
      brNumber: '',
      brFile: '',

      saveBrFile: '',

      uploadError: '',
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),

    id: function () {
      return this.$route.params.id
    },

    userTypeList: function () {
      return this.$userTypeList
    },

    statusList: function () {
      return this.$statusList
    },

    typeList: function () {
      return [
        { text: '普通會員', value: 'normal' },
        { text: 'VIP', value: 'vip' },
      ]
    },
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getUserDetail() {
      let userError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.id),
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_user_by_id', payload)
        this.$func.log('-----Get User Detail-----')
        this.$func.log(result)

        this.name = result.user_name
        this.status = result.status
        this.userType = result.user_type
        this.email = result.email
        this.phone = result.phone
        this.refCode = result.referral_code

        if (this.$validate.DataValid(result.br_number)) {
          this.brNumber = result.br_number
        }

        if (this.$validate.DataValid(result.br_file)) {
          this.brFile = result.br_file
          this.saveBrFile = result.br_file
        }

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get User Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          userError = true
          message = '沒有權限'
        } else {
          userError = true
        }
      }

      if (userError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'member',
        })
      }
    },

    async formSubmit() {
      if (this.$validate.DataValid(this.id) && !this.isFetching) {
        this.setFetching(true)

        this.uploadError = ''
        const isValid = this.$refs['member-form'].validate()

        if (this.userType === 'commercial') {
          if (!this.$validate.DataValid(this.brFile)) {
            this.uploadError = '請上載BR'
          }
        }

        if (!isValid || this.$validate.DataValid(this.uploadError)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          this.setFetching(false)
          return
        }

        try {
          const payload = {
            id: parseInt(this.id),
            data_type: 'user_data',
            verify_token: this.getAdminToken(),
            status: this.status,
            user_name: this.name,
            phone: this.phone,
          }

          if (this.userType === 'commercial') {
            payload.br_number = this.brNumber
            payload.br_file = this.brFile
          }

          const result = await this.$XHR.api('cms_update_user', payload)
          this.$func.log('-----update member detail-----')
          this.$func.log(result)
          this.submitSuccess = true

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'member' },
          })

          this.setShowDialog(true)
          this.setFetching(false)
        } catch (error) {
          this.$func.log('-----update member detail fail-----')
          this.$func.log(error)

          let message = '儲存失敗'

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            message = '沒有權限'
          } else if (error.data === 'user does not exist') {
            message = '用戶不存在'
          }

          this.setFetching(false)
          this.submitSuccess = false
          this.setDialogMessage({
            message: message,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },

    async removeNormalUser(action) {
      action.value = false
      // TODO:
      /* if (action) {
        if (!this.isFetching) {
          this.setFetching(true)

          try {
            const payload = {
              verify_token: this.getAdminToken(),
              id: parseInt(this.id),
            }

            const result = await this.$XHR.api('cms_remove_user', payload)
            this.$func.log('-----remove user-----')
            this.$func.log(result)

            this.setDialogMessage({
              message: '刪除成功',
              isError: false,
              returnLink: { name: 'member' },
            })

            this.setShowDialog(true)
            this.setFetching(false)
          } catch (error) {
            this.$func.log('-----remove user fail-----')
            this.$func.log(error)

            let message = '刪除失敗'

            if (error.data === 'admin verification fail') {
              this.forceLogout()
            } else if (error.data === 'no permission') {
              message = '沒有權限'
            } else if (error.data === 'user does not exist') {
              message = '用戶不存在'
            } else if (error.data === 'this user cannot be removed') {
              message = '不可刪除此用戶'
            }

            this.setFetching(false)
            this.submitSuccess = false
            this.setDialogMessage({
              message: message,
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
          }
        }
      } */
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      if (!this.$validate.DataValid(this.id)) {
        this.$store.dispatch('toggleLoadingPage', false)
      } else {
        this.getUserDetail()
      }
    }
  },
  async beforeDestroy() {
    let removeList = []
    if (this.$store.getters.removedImageList.length > 0) {
      removeList = removeList.concat(this.$store.getters.removedImageList)
    }

    if (this.submitSuccess) {
      if (this.saveBrFile !== this.brFile) {
        const index = removeList.indexOf(this.brFile)
        if (index !== -1) {
          removeList.splice(index, 1)
        }

        removeList.push(this.saveBrFile)
      }
    }

    if (removeList.length > 0) {
      this.$func.log('remove image')
      this.$func.log(removeList)

      const removeImage = await this.$XHR.api('cms_remove_media', {
        verify_token: this.getAdminToken(),
        remove_image_list: removeList,
      })

      this.$func.log('--- remove image success ---')
      this.$func.log(removeImage)
    }

    this.$store.dispatch('resetRemovedImage', [])
  },
}
</script>
